/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import tick from "../../Assets/tick.png";
import party from "../../Assets/party.png";
import arrow from "../../Assets/keeparrow.png";
import star1 from "../../Assets/StarorderCon1.png";
import star2 from "../../Assets/StarorderCon2.png";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { onPostOrder } from "../../Store/Slices/orderSlice";
import {
  onGenerateTokenSubmit,
  onGenerateTokenSubmitReset,
} from "../../Store/Slices/generateTokenSlice";
import axiosIntegration from "../../Common/Axios/axiosIntegration";
import axiosIntegrationOtp from "../../Common/Axios/axiosIntegrationOtp";
import { onValidateLoginTokenSubmit } from "../../Store/Slices/validateLoginTokenSlice";
import Loader from "../../Components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import API from "../../Common/Endpoint/serviceConstrants";
import { onSavePoints } from "../../Store/Slices/cardDataSlice";
const OrderConfirmation = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const paymentID = location.state;
  const [isOptEnable, setIsOptEnable] = useState();
  const [orderDetails, setOrderDetails] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const generateToken = useSelector(
    (state) => state.generateTokenSliceReducer.data
  );
  const clientConfig = useSelector(
    (state) => state.clientConfigurationReducer?.clientConfigurationData?.[0]
  );
  const cardData = useSelector((state) => state.cardDataSliceReducer);
  const validateLoginTokenData = useSelector(
    (state) => state.validateLoginTokenSliceReducer.data
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const clientConfigurationData = useSelector(
    (state) => state.clientConfigurationReducer.clientConfigurationData
  );
  const client_Id = useSelector((state) => state.loginAuthReducer.data);
  const matchedClientData = clientConfigurationData.find(
    (config) =>
      Array.isArray(client_Id) &&
      client_Id?.some((client) => client.clientId === config.clientId)
  );

  useEffect(() => {
    setIsOptEnable(matchedClientData?.otpRedeem);
  }, [clientConfigurationData, client_Id]);

  const getActivationCode = (inde) => {
    const storedOrderDetails = JSON.parse(
      sessionStorage.getItem("orderDetails")
    );
    var q = storedOrderDetails.orderItems[inde].quantity;
    var activationCode = "";
    for (var i = 0; i < q; i++) {
      activationCode += generateUniqueNumber();
      activationCode += ";";
    }
    return activationCode;
  };
  const generateFormattedActivationCode = () => {
    // Generates a single formatted code like 'hrth-ghnej3-htek'
    const randomStringSegment = () =>
      Math.random().toString(36).substring(2, 6); // Short random string
    const randomAlphanumericSegment = () =>
      Math.random().toString(36).substring(2, 8); // Alphanumeric string

    return `${randomStringSegment()}-${randomAlphanumericSegment()}-${randomStringSegment()}`;
  };

  const getActivationString = (inde) => {
    const storedOrderDetails = JSON.parse(
      sessionStorage.getItem("orderDetails")
    );
    const quantity = storedOrderDetails.orderItems[inde].quantity;
    let activationCode = "";

    for (let i = 0; i < quantity; i++) {
      activationCode += generateFormattedActivationCode();
      if (i < quantity - 1) activationCode += ";";
    }

    return activationCode;
  };

  function generateUniqueNumber() {
    const part1 = Math.floor(Math.random() * 100000);
    const part2 = Math.floor(Math.random() * 1000000);
    const part3 = Math.floor(Math.random() * 100000);

    return `${part1} ${part2} ${part3}`;
  }
  const loginToken = useSelector(
    (state) => state.validateLoginTokenSliceReducer
  );
  useEffect(() => {
    const processOrder = async () => {
      const today = new Date();
      const nextYearDate = new Date(today);
      nextYearDate.setFullYear(today.getFullYear() + 1);

      const storedOrderDetails = JSON.parse(
        sessionStorage.getItem("orderDetails")
      );
      if (!storedOrderDetails) return;

      setOrderDetails(storedOrderDetails);

      for (const [index, order] of storedOrderDetails?.orderItems.entries()) {
        dispatch(
          onPostOrder({
            orderId: storedOrderDetails?.orderId,
            clientId: 98,
            memberId: validateLoginTokenData.memberIdentifier,
            supplierId: 2,
            clientBrandId: storedOrderDetails.id,
            orderAmount: storedOrderDetails.totalPayableAmount,
            paymentId: paymentID?.paymentid || "q",
            orderStatus: "confirm",
            burnPoints: storedOrderDetails?.burnPoints,
            earnPoints: parseInt(storedOrderDetails.earnPoints) || 0,
            name: storedOrderDetails.name,
            mobile: validateLoginTokenData.mobileNumber,
            email: validateLoginTokenData.emailId,
            cardName: storedOrderDetails?.cardName,
            cardNetwork: "string",
            cardType: storedOrderDetails.orderItems[index].quantity.toString(),
            denomination: order.denomination,
            itemAmount: parseFloat(order.you_pay),
            cardNo: "string",
            cardpin: "string",
            activationCode: getActivationCode(index),
            activationUrl: getActivationString(index),
            expiryDate: nextYearDate,
            noOfOrder: 1,
            commission: 56,
            sumOfCommissionAmount: 0,
          })
        );

        dispatch(onGenerateTokenSubmit());

        if (parseInt(storedOrderDetails.burnPoints) > 0) {
          try {
            await burnPoints(
              storedOrderDetails?.orderId,
              storedOrderDetails.burnPoints,
              setLoading
            );
          } catch (error) {
            throw error;
          }
        }
      }

      sessionStorage.removeItem("orderDetails");
    };
    processOrder();
  }, []);

  const callSwitchAccountApi = async (payload) => {
    debugger;
    axiosIntegration.defaults.headers[
      "access-token"
    ] = `${generateToken?.accessToken}`;
    try {
      const { data = [] } = await axiosIntegration.post(
        API.switchAccountApi,
        payload
      );

      dispatch(
        onSavePoints({
          points: data?.response?.memberBalance?.pointBalance,
          identifierType: data?.response?.identifierType,
          subIdentifier: data?.response?.subIdentifier,
        })
      );
    } catch (error) {
      console.log("Error calling switch account API:", error);
      throw error;
    }
  };
  const sendMail = async (payload) => {
    axiosIntegration.defaults.headers[
      "access-token"
    ] = `${generateToken?.accessToken}`;

    try {
      await axiosIntegration.post(API.mailApi, payload);
    } catch (error) {
      console.error("Error calling switch account API:", error);
      throw error; // Rethrow error for handling in selectCard
    }
  };
  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const amountToRedeem = (points) => {
    let pointValue = clientConfig.points;
    let price = clientConfig.price;
    let totalPoints = points;
    let totalAmount = totalPoints * pointValue * price;
    return parseFloat(totalAmount).toFixed(2);
  };

  const burnPoints = async (orderId, points, setLoading) => {
    const payload = {
      culture: 1,
      partnerCode: process.env.REACT_APP_PARTNER_CODE,
      transactionNo: orderId,
      memberIdentifier: validateLoginTokenData.memberIdentifier,
      transactionDate: getCurrentDateTime(),
      amountToRedeem: amountToRedeem(points),
      pointsToRedeem: [
        {
          point: points,
          integrationPartnerCode: parseInt(
            process.env.REACT_APP_INTEGRATION_PARTNER_CODE
          ),
        },
      ],
      redemptionPartnerCode: process.env.REACT_APP_PARTNER_CODE,
      optionalIdentifier: cardData?.subIdentifier,
      optionalIdentifierType: cardData?.identifierType,
    };

    setLoading(true);
    try {
      const result = await axiosIntegration.post("/RedeemPoint", payload, {
        headers: {
          "Content-Type": "application/json",
          ClientCode: process.env.REACT_APP_CLIENT_CODE,
          PartnerCode: process.env.REACT_APP_PARTNER_CODE_API,
          "access-token": generateToken.accessToken,
        },
      });


      // Await the switch account API call
      await callSwitchAccountApi({
        identifier: loginToken?.data?.memberIdentifier,
        subIdentifier: cardData?.subIdentifier,
        identifierType: cardData?.identifierType,
        userSessionToken: sessionStorage.getItem("temporaryToken"),
        isAccountSwitch: false,
      });
      const mailPayload = {
        toEmailId: "jatingupta9213037402@gmail.com",
        templateCode: "ETS001",
        content: "<p>hi</p>",
        subject: "Special Offer Claim",
        contentValue: {
          UserName: "",
        },
      };
      await sendMail(mailPayload);
      return result.data;
    } catch (error) {
      throw error;
    } finally {

      setLoading(false);
      toast.success("E-Gift card purchased successfully");

    }
  };

  useEffect(() => {
    if (generateToken?.status_code === 200) {
      axiosIntegration.defaults.headers[
        "access-token"
      ] = `${generateToken?.data?.accessToken}`;
      axiosIntegrationOtp.defaults.headers[
        "access-token"
      ] = `${generateToken?.data?.accessToken}`;
      dispatch(
        onValidateLoginTokenSubmit({
          loginSessionToken: sessionStorage.getItem("loginSessionToken"),
          integrationSecretKey: process.env.REACT_APP_INTEGRATION_SECRET_KEY,
          temporaryToken: sessionStorage.getItem("temporaryToken"),
          urlReferrer: "",
        })
      );

      dispatch(onGenerateTokenSubmitReset());
    }
  }, [generateToken]);

  const handleRetrieveCode = (id, deno) => {
    isOptEnable
      ? navigate("/otp", { state: { orderId: id, denomination: deno } })
      : navigate(`/order-detail?orderID=${id}&denomination=${deno}`);
  };
  return (
    <>
      <ScrollToTop />
      <ToastContainer />
      {loading ? (
        <Loader />
      ) : (
        <div className="bg-white">
          <section
            className={`py-xl-3 pt-1 pb-2 mar-4 ${
              isMobile ? "mobile-view" : "desktop-hide"
            }`}
          >
            <div className="container-fluid">
              <div className="m-xl-n3 m-md-n2 m-n1">
                <div className="p-md-2 p-1 order_conf text-center">
                  {/* <i className="fa-solid fa-arrow-left clr-black-blk"></i> &nbsp; */}
                  Order Confirmation
                </div>
              </div>
            </div>
          </section>

          <section className="m-2 mt-4">
            <div className="container p-2">
              <div className="row justify-content-center">
                <div className="col-md-5 col-12 card-conf">
                  <img className="party" src={party} alt="Party" />
                  <div className="bg-pink">
                    <div className="d-flex justify-content-start p-2">
                      <img className="w-50px tick" src={tick} alt="Tick" />
                      <div className="thankyoubox2">
                        <p className="onep">Thank You</p>
                        <p className="secondppp">
                          E-Gift card purchased successfully
                        </p>
                      </div>
                    </div>
                    <hr className="hz-line" />
                    <div className="d-flex justify-content-between">
                      <div className="thankyoubox3">
                        <p className="thank-ans">{orderDetails?.orderId}</p>
                        <p className="thank-qns">Order Number</p>
                      </div>
                      <div className="thankyoubox3">
                        <p className="thank-ans">
                          <img src={isMobile ? star1 : star2} alt="star" />
                          &nbsp;{orderDetails?.burnPoints} + ₹
                          {orderDetails?.totalPayableAmount}
                        </p>
                        <p className="thank-qns">Order Amount</p>
                      </div>
                      <div className="thankyoubox3"></div>
                    </div>
                    <hr className="hz-line" />
                    <div className="d-flex justify-content-between pb-md-1">
                      <div className="thankyoubox3">
                        <p className="keep-thank">
                          Keep Shopping to earn more!
                        </p>
                      </div>
                      <div className="thankyoubox3">
                        <p className="onep">
                          <img src={arrow} alt="Keep Arrow" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="loyo mt-md-5 mt-4">
                    <p className="dis-thank">
                      The Order details have been sent to your IDFC FIRST Bank
                      registered mobile number and email id. You will receive an
                      email from ………..
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-12 mt-md-0 mt-4">
                  <div className="side-newthank mb-0 mb-md-4">
                    <div className="d-flex w-100 h-100 justify-content-center">
                      <Link to="/myntra">
                        <div className="w-100 order_confirmation_img_div">
                          <img
                            className="order_confirmation_img"
                            alt="card-image"
                            src={orderDetails?.cardImage}
                          />
                        </div>
                      </Link>
                    </div>

                    <div className="card card_main_box mt-3">
                      <div className="card-body amazonPay_e_Gift_Card">
                        <div className="container pt-2">
                          <h5 className="card-title">
                            {orderDetails?.cardName}
                          </h5>
                          <div className="row header-row">
                            <div className="col-4 lines3_col_4 t_head py-2 text-center">
                              <strong>Value / Qty</strong>
                            </div>
                            <div className="col-4 lines3_col_4 t_head py-2 text-center">
                              <strong>You Paid</strong>
                            </div>
                            <div className="col-4 lines3_col_4 t_head py-2 text-center">
                              <strong>Code</strong>
                            </div>
                          </div>

                          {orderDetails?.orderItems?.map((card, index) => (
                            <div className="row card-row" key={index}>
                              <div className="col-4 lines3_col_4 t_body py-2 text-center">
                                {card?.denomination}/{card?.quantity}
                              </div>
                              <div className="col-4 lines3_col_4 t_body py-2 text-center">
                                {card?.total}
                              </div>
                              <div className="col-4 lines3_col_4 t_body py-2 text-center">
                                <span
                                  onClick={() =>
                                    handleRetrieveCode(
                                      orderDetails.orderId,
                                      orderDetails.orderItems?.[0]?.denomination
                                    )
                                  }
                                  className="text-primary"
                                >
                                  Get Code
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default OrderConfirmation;
